import { STRUCT_API_VERSION } from "@/app/constants";
import { structAPIFetchClient } from "@/app/services/client";
import {
  StructChat,
  StructChatResponse,
  StructThread,
} from "@/app/types/Thread.type";

const CHAT_ENDPOINT = `/${STRUCT_API_VERSION}/chats`;

interface createChatMessageArgs {
  threadId: StructThread["id"];
  message: StructChat["text"];
  attachments?: StructChat["attachments"];
  reply_to?: StructChat["reply_to"];
}

export const createChatMessageAPI = ({
  threadId,
  message,
  attachments,
  reply_to,
}: createChatMessageArgs) =>
  structAPIFetchClient(`${CHAT_ENDPOINT}`, {
    method: "POST",
    body: JSON.stringify({
      thread_id: threadId,
      text: message,
      attachments,
      reply_to,
    }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

interface updateChatMessageArgs {
  messageId: string;
  threadId: string;
  message: string;
}

export const updateChatMessageAPI = async ({
  messageId,
  threadId,
  message,
}: updateChatMessageArgs) =>
  await structAPIFetchClient(CHAT_ENDPOINT, {
    method: "PUT",
    body: JSON.stringify({
      id: messageId,
      thread_id: threadId,
      text: message,
    }),
  });

interface deleteChatMessageArgs {
  messageId: string;
  threadId: string;
}

export const deleteChatMessageAPI = async ({
  messageId,
  threadId,
}: deleteChatMessageArgs) =>
  await structAPIFetchClient(CHAT_ENDPOINT, {
    method: "PUT",

    body: JSON.stringify({
      id: messageId,
      thread_id: threadId,
      bits: {
        deleted: true,
      },
    }),
  });

interface fetchChatsArgs {
  threadId: string;
  messageCount?: number;
  beforeChatId?: string;
  showError?: boolean;
}

export const fetchChatsAPI = async ({
  threadId,
  messageCount: num,
  beforeChatId: before,
  showError = true,
}: fetchChatsArgs) => {
  let url = `/${STRUCT_API_VERSION}/chats/${threadId}`;
  const params = new URLSearchParams();
  if (num) params.append("num", num.toString());
  if (before) params.append("before", before);
  if (params.toString()) url += `?${params.toString()}`;

  return await structAPIFetchClient(
    url,
    {
      method: "GET",
    },
    showError,
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(({ data }) => data as StructChatResponse[]);
};

interface PostReactionsAPIArgs {
  messageId: string;
  name: string;
  action: "set" | "delete";
}

export const postReactionsAPI = async ({
  messageId,
  name,
  action,
}: PostReactionsAPIArgs) =>
  await structAPIFetchClient(`/${STRUCT_API_VERSION}/reactions`, {
    method: "POST",

    body: JSON.stringify({
      chat_id: messageId,
      name,
      [action]: true,
    }),
  });

const READ_ENDPOINT = `/${STRUCT_API_VERSION}/read`;

interface MarkAsReadArgs {
  threadId: string;
}
interface BookmarkArgs {
  threadId: string;
  isBookmarked: boolean;
}

export const postChatReadAPI = async ({ threadId }: MarkAsReadArgs) =>
  await structAPIFetchClient(READ_ENDPOINT, {
    method: "PUT",
    body: JSON.stringify({
      thread_id: threadId,
    }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

export const toggleBookmarkAPI = async ({
  threadId,
  isBookmarked,
}: BookmarkArgs) =>
  await structAPIFetchClient(READ_ENDPOINT, {
    method: "PUT",
    body: JSON.stringify({
      thread_id: threadId,
      bits: {
        thread_bookmarked: true,
        flip: !isBookmarked,
      },
    }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

export const putFeedReadAPI = async ({
  feedId,
  threadId,
  threadIds,
  flip = false,
  isUnread = true,
  isHidden = false,
}: {
  feedId?: string;
  threadId?: string;
  threadIds?: string[];
  flip?: boolean;
  isUnread?: boolean;
  isHidden?: boolean;
}) =>
  await structAPIFetchClient(READ_ENDPOINT, {
    method: "PUT",
    body: JSON.stringify({
      feed_id: feedId,
      thread_id: threadId,
      thread_ids: threadIds,
      bits: {
        ...(isUnread && { feed_unread: true }),
        ...(isHidden && !isUnread && { feed_hidden: true }),
        flip,
      },
    }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });

export const forkChatsAPI = async ({
  chatIds,
}: {
  chatIds: StructChatResponse["id"][];
}) =>
  await structAPIFetchClient(`/${STRUCT_API_VERSION}/fork`, {
    method: "POST",
    body: JSON.stringify({
      chat_ids: chatIds,
    }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  });
