import * as Tooltip from "@radix-ui/react-tooltip";
import { ReactNode } from "react";
import styles from "./ReactionsTooltip.module.scss";
import { Flex, Theme } from "@radix-ui/themes";

interface ReactionsTooltipProps {
  content: ReactNode;
  children: ReactNode;
}

const ReactionsTooltip = ({ content, children }: ReactionsTooltipProps) => (
  <Tooltip.Provider delayDuration={0}>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
      <Tooltip.Portal>
        <Theme accentColor="indigo" grayColor="slate" radius="large" asChild>
          <Tooltip.Content sideOffset={5} asChild>
            <Flex className={styles.reactionTooltip}>
              {content}
              <Tooltip.Arrow className={styles.arrow} />
            </Flex>
          </Tooltip.Content>
        </Theme>
      </Tooltip.Portal>
    </Tooltip.Root>
  </Tooltip.Provider>
);

export default ReactionsTooltip;
