import React from "react";
import { ReactionResponse, StructUser } from "@/app/types/Thread.type";
import { StructAuthenticatedUser } from "@/app/types/session";
import { Flex, Text } from "@radix-ui/themes";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import dynamic from "next/dynamic";
import { BlockType } from "@/app/types/Block.type";

const Emoji = dynamic(() => import("../Emoji").then((mod) => mod.Emoji));
const CustomEmoji = dynamic(() =>
  import("../CustomEmoji").then((mod) => mod.CustomEmoji),
);

interface ReactionsTooltipProps {
  reactions: ReactionResponse[];
  currentUserID?: StructAuthenticatedUser["id"];
  showPeople?: boolean;
}

const ReactionsTooltipContent = ({
  reactions,
  currentUserID,
  showPeople = true,
}: ReactionsTooltipProps) => {
  const getEmojiNameString = () =>
    reactions.map((reaction) => `:${reaction.block.name}:`).join(" ");

  const reactionCount = reactions.length;
  const displayedReactions = reactions.slice(0, 7);

  const getPeopleText = () => {
    if (!showPeople) return "People";
    if (reactionCount < 0) {
      return reactionCount === 1 ? "1 person" : `${reactionCount} people`;
    }

    const nameList = reactions.flatMap(
      (reaction) =>
        reaction.user_ids?.map((id) =>
          id === currentUserID
            ? "You"
            : reaction?.entity_map?.[id] || "Unknown",
        ),
    );

    const uniqueNames = [...new Set(nameList)];

    const sortedNames = uniqueNames.includes("You")
      ? ["You", ...uniqueNames.filter((name) => name !== "You")]
      : uniqueNames;

    return sortedNames.length === 1
      ? sortedNames[0]
      : sortedNames.slice(0, -1).join(", ") +
          " and " +
          sortedNames[sortedNames.length - 1];
  };

  return (
    <Flex
      align="center"
      direction="column"
      style={{
        maxWidth: 240,
      }}
    >
      <Flex wrap="wrap" position="relative">
        {displayedReactions.map((reaction) => (
          <Flex mr="1" key={`${reaction.block.unicode}`}>
            {reaction.block.type === BlockType.CustomEmoji ? (
              <CustomEmoji unicode={reaction?.block?.name} />
            ) : (
              <Emoji unicode={reaction.block.unicode} />
            )}
          </Flex>
        ))}
        {reactionCount > 7 && (
          <Flex align="center">
            <Flex
              align="center"
              justify="center"
              style={{
                position: "absolute",
                bottom: 0,
                right: "var(--space-4)",
                width: "var(--space-6)",
                height: "100%",
                background:
                  "linear-gradient(to right, var(--gray-a1), var(--gray-12))",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                verticalAlign: "middle",
              }}
            />
            <DotsHorizontalIcon
              style={{
                height: "var(--space-4)",
                width: "var(--space-4)",
                color: "var(--slate-11)",
              }}
            />
          </Flex>
        )}
      </Flex>
      <Text
        size="1"
        weight="medium"
        style={{
          color: "var(--slate-7)",
        }}
      >
        <Text
          weight="bold"
          style={{
            color: "var(--slate-1)",
          }}
        >
          {getPeopleText()}
        </Text>{" "}
        {reactionCount > 7
          ? "reacted with many emojis!"
          : `reacted with ${getEmojiNameString()}`}
      </Text>
    </Flex>
  );
};

export default ReactionsTooltipContent;
