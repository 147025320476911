import React from "react";
import styles from "./ThreadReactions.module.scss";
import { Flex, Text } from "@radix-ui/themes";
import { ReactionResponse } from "@/app/types/Thread.type";

import dynamic from "next/dynamic";
import ReactionsTooltipContent from "@/app/components/Reactions/ReactionsTooltipContent";
import ReactionsTooltip from "@/app/components/Reactions/ReactionsTooltip";
import { BlockType } from "@/app/types/Block.type";
const Emoji = dynamic(() => import("../Emoji").then((mod) => mod.Emoji));
const CustomEmoji = dynamic(() =>
  import("../CustomEmoji").then((mod) => mod.CustomEmoji),
);

interface ThreadReactionsProps {
  reactions: ReactionResponse[];
}

const MAX_REACTIONS = 100;

const ThreadReactions = ({ reactions }: ThreadReactionsProps) => {
  const sortedReactions = reactions
    .sort((a, b) => b.count - a.count)
    .slice(0, MAX_REACTIONS);

  const totalReactionCount = reactions
    .map(({ count }) => count)
    .reduce((partialSum, count) => partialSum + count, 0);

  const renderReaction = (reaction: ReactionResponse) => {
    if (!reaction?.block?.unicode) return null;
    return (
      <Flex mr="1" key={`${reaction?.block?.unicode}`}>
        {reaction?.block?.type === BlockType.CustomEmoji ? (
          <CustomEmoji unicode={reaction?.block?.name} />
        ) : (
          <Emoji unicode={reaction?.block?.unicode} />
        )}
      </Flex>
    );
  };

  return (
    <ReactionsTooltip
      content={
        <ReactionsTooltipContent showPeople={false} reactions={reactions} />
      }
    >
      <div className={styles.reactions}>
        {sortedReactions
          .slice(0, 3)
          .map((reaction) => renderReaction(reaction))}
        <Text size="1" weight="medium">
          {totalReactionCount}
        </Text>
      </div>
    </ReactionsTooltip>
  );
};

export default ThreadReactions;
