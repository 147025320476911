import { Flex } from "@radix-ui/themes";
import styles from "./TypingAnimation.module.scss";
import React from "react";

export const TypingAnimation = () => (
  <Flex className={styles.typingAnimation}>
    <span></span>
    <span></span>
    <span></span>
  </Flex>
);
