import { Text } from "@radix-ui/themes";
import { CSSProperties } from "react";

interface TypingIndicatorProps {
  names: string[];
  style?: CSSProperties;
}

const TypingIndicator = ({ names, style }: TypingIndicatorProps) => {
  switch (names.length) {
    case 0:
      return null;
    case 1:
      return (
        <Text size="1" weight="regular" style={style}>
          <Text weight="medium">{names[0]}</Text> is typing...
        </Text>
      );
    case 2:
      return (
        <Text size="1" weight="regular" style={style}>
          <Text weight="medium">{names[0]}</Text> and{" "}
          <Text weight="medium">{names[1]}</Text> are typing...
        </Text>
      );
    default:
      const othersCount = names.length - 2;

      return (
        <Text size="1" weight="regular" style={style}>
          <Text weight="medium">{names[0]}</Text>,{" "}
          <Text weight="medium">{names[1]}</Text> and{" "}
          <Text weight="medium">{othersCount}</Text> other user
          {othersCount > 1 ? "s" : ""} are typing...
        </Text>
      );
  }
};

export default TypingIndicator;
