import { IconType } from "@/app/components/icons/icon.type";

export const ConnectIcon = ({ style, className }: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    style={style}
    className={className}
  >
    <rect width="16" height="16" fill="white" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.94455 10.2232C3.98855 10.2232 4.86448 9.5036 5.10339 8.53334H10.8965C11.1354 9.5036 12.0114 10.2232 13.0554 10.2232C14.2832 10.2232 15.2786 9.22783 15.2786 7.99997C15.2786 6.77211 14.2832 5.77673 13.0554 5.77673C12.0113 5.77673 11.1354 6.49637 10.8965 7.46667H5.10341C4.86453 6.49637 3.98858 5.77673 2.94455 5.77673C1.71669 5.77673 0.721313 6.77211 0.721313 7.99997C0.721313 9.22783 1.71669 10.2232 2.94455 10.2232ZM14.2786 7.99997C14.2786 8.67554 13.7309 9.22321 13.0554 9.22321C12.3798 9.22321 11.8321 8.67554 11.8321 7.99997C11.8321 7.32439 12.3798 6.77673 13.0554 6.77673C13.7309 6.77673 14.2786 7.32439 14.2786 7.99997Z"
      fill="currentColor"
    />
  </svg>
);
