/**
 * Fork of https://npm.io/slack-markdown
 */
const markdown = require("simple-markdown");
const emoji = require("node-emoji");

function htmlTag(
  tagName: string,
  content: string,
  attributes: any,
  isClosed = true,
  state: stateType,
) {
  if (typeof isClosed === "object") {
    state = isClosed;
    isClosed = true;
  }
  if (!attributes) {
    attributes = {};
  }
  if (attributes.class) {
    attributes.class = attributes.class
      .split(" ")
      .map((cl: any) => state.cssModuleNames[cl] || cl)
      .join(" ");
  }
  let attributeString = "";
  for (let attr in attributes) {
    if (
      Object.prototype.hasOwnProperty.call(attributes, attr) &&
      attributes[attr]
    ) {
      attributeString += ` ${markdown.sanitizeText(
        attr,
      )}="${markdown.sanitizeText(attributes[attr])}"`;
    }
  }

  const unclosedTag = `<${tagName}${attributeString}>`;
  return isClosed ? unclosedTag + content + `</${tagName}>` : unclosedTag;
}

(markdown.htmlTag as any) = htmlTag;

function htmlSlackTag(content: string, attributes: any, state: stateType) {
  if (state.noExtraSpanTags) {
    return content;
  }
  return htmlTag("span", content, attributes, true, state);
}

export const rulesUniversal = {
  emoji: {
    order: markdown.defaultRules.strong.order,
    match: (source: string) => /^:([a-zA-Z0-9_\-+]+):/.exec(source),
    parse: (capture: any) => {
      const code = capture[1];

      // slack uses <emoji>_face sometimes, so fallback to that
      const result = emoji.find(code) || emoji.find(code + "_face");

      return {
        content: result ? result.emoji : `:${code}:`,
        isEmoji: !!result,
      };
    },
    html: (node: any, output: any, state: stateType) => {
      const content = markdown.sanitizeText(node.content);
      if (!node.isEmoji || state.noExtraEmojiSpanTags) return content;
      return htmlTag("span", content, { class: "s-emoji" }, true, state);
    },
  },
  text: Object.assign({}, markdown.defaultRules.text, {
    match: (source: string) =>
      /^[\s\S]+?(?=[^0-9A-Za-z\s\u00c0-\uffff-]|\n\n|\n|\w+:\S|$)/.exec(source),
    html: (node: any, output: any, state: stateType) => {
      const content = emoji.emojify(node.content);
      if (state.escapeHTML) {
        return markdown.sanitizeText(content);
      }

      return content;
    },
  }),
};

export const rules = {
  blockQuote: Object.assign({}, markdown.defaultRules.blockQuote, {
    match: (source: string, state: stateType, prevSource: string) =>
      !/^$|\n *$/.test(prevSource) || state.inQuote
        ? null
        : /^( *> [^\n]*(\n *> [^\n]*)*\n?)/.exec(source),
    parse: (capture: any, parse: any, state: stateType) => {
      const all = capture[0];
      const removeSyntaxRegex = /^ *> ?/gm;
      const content = all.replace(removeSyntaxRegex, "");

      state.inQuote = true;
      state.inline = true;
      const parsed = parse(content, state);
      state.inQuote = state.inQuote || false;
      state.inline = state.inline || false;

      return {
        content: parsed,
        type: "blockQuote",
      };
    },
  }),
  codeBlock: Object.assign({}, markdown.defaultRules.codeBlock, {
    match: markdown.inlineRegex(/^```([^]+?`*)\n*```/i),
    parse: (capture: any, parse: any, state: stateType) => {
      return {
        content: capture[1] || "",
        inQuote: state.inQuote || false,
      };
    },
    html: (node: any, output: any, state: stateType) => {
      const codeHtml = node.content.replace(
        /<(https?:\/\/[\w]+(?:\.[\w]+)+(?:\/[\w?=%&@$#_.+]+)*\/?)(?:\|((?:[^>])+)>)?>/gm,
        (_: any, link: string) => link,
      );
      return htmlTag(
        "pre",
        htmlTag("code", codeHtml, null, true, state),
        null,
        true,
        state,
      );
    },
  }),
  newline: markdown.defaultRules.newline,
  autolink: Object.assign({}, markdown.defaultRules.autolink, {
    order: markdown.defaultRules.strong.order + 1,
    match: markdown.inlineRegex(
      /^<((?:(?:(?:ht|f)tps?|ssh|irc):\/\/|mailto:|tel:)[^|>]+)(\|([^>]*))?>/,
    ),
    parse: (capture: any, parse: any, state: stateType) => {
      const content = capture[3]
        ? parse(capture[3], state)
        : [
            {
              type: "text",
              content: capture[1],
            },
          ];
      return {
        content,
        target: capture[1],
      };
    },
    html: (node: any, output: any, state: stateType) => {
      const attrs = {
        href: markdown.sanitizeUrl(node.target),
        target: "",
        rel: "",
      };
      if (state.hrefTarget) {
        attrs.target = state.hrefTarget;
      }

      if (
        state.hrefFollowURLsFromHosts &&
        state.hrefFollowURLsFromHosts?.length > 0 &&
        attrs.href
      ) {
        try {
          const url = new URL(attrs.href);
          // get the main domain and check if it matches the host, only follow if the domains are in the same domain
          const targetHost = url.host.split(".").slice(-2).join(".");
          // check if the host contains http or https, remove them and get only host
          const hrefFollowHosts = state.hrefFollowURLsFromHosts.map((host) =>
            new URL(host.startsWith("http") ? host : `https://${host}`).host
              .split(".")
              .slice(-2)
              .join("."),
          );
          const followHosts = [...hrefFollowHosts, "struct.ai", "struct.co"];
          if (!followHosts.find((host) => host === targetHost)) {
            attrs.rel = "nofollow";
          }
        } catch (error) {
          console.error(error);
          console.error({
            followHost: state.hrefFollowURLsFromHosts,
            href: attrs.href,
          });
        }
      }
      return htmlTag("a", output(node.content, state), attrs, true, state);
    },
  }),
  url: Object.assign({}, markdown.defaultRules.url, {
    parse: (capture: any) => {
      return {
        content: [
          {
            type: "text",
            content: capture[1],
          },
        ],
        target: capture[1],
      };
    },
    html: (node: any, output: any, state: stateType) => {
      const attrs = {
        href: markdown.sanitizeUrl(node.target),
        target: "",
        rel: "",
      };
      if (state.hrefTarget) {
        attrs.target = state.hrefTarget;
      }

      if (state.hrefFollowURLsFromHosts && attrs.href) {
        try {
          const url = new URL(attrs.href);
          // get the main domain and check if it matches the host, only follow if the domains are in the same domain
          const targetHost = url.host.split(".").slice(-2).join(".");
          // check if the host contains http or https, remove them and get only host
          const hrefFollowHosts = state.hrefFollowURLsFromHosts.map((host) =>
            new URL(host.startsWith("http") ? host : `https://${host}`).host
              .split(".")
              .slice(-2)
              .join("."),
          );
          const followHosts = [...hrefFollowHosts, "struct.ai", "struct.co"];
          if (!followHosts.find((host) => host === targetHost)) {
            attrs.rel = "nofollow";
          }
        } catch (error) {
          console.error(error);
          console.error({
            followHost: state.hrefFollowURLsFromHosts,
            href: attrs.href,
          });
        }
      }

      return htmlTag("a", output(node.content, state), attrs, true, state);
    },
  }),
  noem: {
    order: markdown.defaultRules.text.order,
    match: (source: string) => /^\\_/.exec(source),
    parse: function () {
      return {
        type: "text",
        content: "\\_",
      };
    },
    html: function (node: any, output: any, state: stateType) {
      return output(node.content, state);
    },
  },
  em: Object.assign({}, markdown.defaultRules.em, {
    match: markdown.inlineRegex(/^\b_(\S(?:\\[\s\S]|[^\\])*?\S|\S)_(?!_)\b/),
    parse: (capture: any, parse: any) => {
      return {
        content: parse(capture[1]),
        type: "em",
      };
    },
  }),
  strong: Object.assign({}, markdown.defaultRules.strong, {
    match: markdown.inlineRegex(/^\*(\S(?:\\[\s\S]|[^\\])*?\S|\S)\*(?!\*)/),
  }),
  strike: Object.assign({}, markdown.defaultRules.del, {
    match: markdown.inlineRegex(/^~(\S(?:\\[\s\S]|[^\\])*?\S|\S)~(?!~)/),
  }),
  inlineCode: {
    order: markdown.defaultRules.strong.order,
    match: (source: any) => /^(`+)([\s\S]*?[^`])\1(?!`)/.exec(source),
    parse: (capture: any) => ({
      content: capture[2].replace(/^ (?= *`)|(` *) $/g, "$1"),
    }),
    html: (node: any, output: any, state: stateType) =>
      state.noInlineCode
        ? node.content
        : htmlTag(
            "code",
            markdown.sanitizeText(node.content),
            null,
            true,
            state,
          ),
  },
  br: Object.assign({}, markdown.defaultRules.br, {
    match: markdown.anyScopeRegex(/^\n/),
  }),
};

const slackCallbackDefaults = {
  user: (node: any) => "@" + (node.name || node.id),
  channel: (node: any) => "#" + (node.name || node.id),
  usergroup: (node: any) => "^" + (node.name || node.id),
  atHere: (node: any) => "@" + (node.name || "here"),
  atChannel: (node: any) => "@" + (node.name || "channel"),
  atEveryone: (node: any) => "@" + (node.name || "everyone"),
  date: (node: any) => node.fallback,
};

export const rulesSlack = {
  slackUser: {
    order: markdown.defaultRules.strong.order,
    match: (source: string) => /^<@([^|>]+)(\|([^>]*))?>/.exec(source),
    parse: (capture: any, parse: any, state: stateType) => {
      const name = capture[3] ? parse(capture[3], state) : "";
      return {
        id: capture[1],
        content: name,
      };
    },
    html: (node: any, output: any, state: stateType) => {
      const newNode = {
        id: node.id,
        name: node.content ? output(node.content, state) : "",
      };
      return htmlSlackTag(
        state.slackCallbacks.user(newNode),
        { class: "s-mention s-user" },
        state,
      );
    },
  },
  slackChannel: {
    order: markdown.defaultRules.strong.order,
    match: (source: string) => /^<#([^|>]+)(\|([^>]*))?>/.exec(source),
    parse: (capture: any, parse: any, state: stateType) => {
      const name = capture[3] ? parse(capture[3], state) : "";
      return {
        id: capture[1],
        content: name,
      };
    },
    html: (node: any, output: any, state: stateType) => {
      const newNode = {
        id: node.id,
        name: node.content ? output(node.content, state) : "",
      };
      return htmlSlackTag(
        state.slackCallbacks.channel(newNode),
        { class: "s-mention s-channel" },
        state,
      );
    },
  },
  slackUserGroup: {
    order: markdown.defaultRules.strong.order,
    match: (source: string) => /^<!subteam\^([^|>]+)(\|([^>]+))?>/.exec(source),
    parse: (capture: any, parse: any, state: stateType) => {
      const name = capture[3] ? parse(capture[3], state) : "";
      return {
        id: capture[1],
        content: name,
      };
    },
    html: (node: any, output: any, state: stateType) => {
      const newNode = {
        id: node.id,
        name: node.content ? output(node.content, state) : "",
      };
      return htmlSlackTag(
        state.slackCallbacks.usergroup(newNode),
        { class: "s-mention s-usergroup" },
        state,
      );
    },
  },
  slackAtHere: {
    order: markdown.defaultRules.strong.order,
    match: (source: string) => /^<!here(\|([^>]*))?>/.exec(source),
    parse: (capture: any, parse: any, state: stateType) => {
      const name = capture[2] ? parse(capture[2], state) : "";
      return {
        content: name,
      };
    },
    html: (node: any, output: any, state: stateType) => {
      const newNode = {
        name: node.content ? output(node.content, state) : "",
      };
      return htmlSlackTag(
        state.slackCallbacks.atHere(newNode),
        { class: "s-mention s-at-here" },
        state,
      );
    },
  },
  slackAtChannel: {
    order: markdown.defaultRules.strong.order,
    match: (source: string) => /^<!channel(\|([^>]*))?>/.exec(source),
    parse: (capture: any, parse: any, state: stateType) => {
      const name = capture[2] ? parse(capture[2], state) : "";
      return {
        content: name,
      };
    },
    html: (node: any, output: any, state: stateType) => {
      const newNode = {
        name: node.content ? output(node.content, state) : "",
      };
      return htmlSlackTag(
        state.slackCallbacks.atChannel(newNode),
        { class: "s-mention s-at-channel" },
        state,
      );
    },
  },
  slackAtEveryone: {
    order: markdown.defaultRules.strong.order,
    match: (source: string) => /^<!everyone(\|([^>]*))?>/.exec(source),
    parse: (capture: any, parse: any, state: stateType) => {
      const name = capture[2] ? parse(capture[2], state) : "";
      return {
        content: name,
      };
    },
    html: (node: any, output: any, state: stateType) => {
      const newNode = {
        name: node.content ? output(node.content, state) : "",
      };
      return htmlSlackTag(
        state.slackCallbacks.atEveryone(newNode),
        { class: "s-mention s-at-everyone" },
        state,
      );
    },
  },
  slackDate: {
    order: markdown.defaultRules.strong.order,
    match: (source: string) =>
      /^<!date\^([^|>^]+)\^([^|>^]+)(\^([^|>^]+))?(\|([^>]*))?>/.exec(source),
    parse: (capture: any, parse: any, state: stateType) => {
      const name = capture[6] ? parse(capture[6], state) : "";
      const timestamp = capture[1];
      const format = capture[2];
      const link = capture[4];
      return {
        timestamp,
        format,
        link,
        content: name,
      };
    },
    html: (node: any, output: any, state: stateType) => {
      const newNode = {
        timestamp: node.timestamp,
        format: node.format,
        link: node.link,
        fallback: node.content ? output(node.content, state) : "",
      };
      return htmlSlackTag(
        state.slackCallbacks.date(newNode),
        { class: "s-mention s-date" },
        state,
      );
    },
  },
};

const slackOnlyRules = {
  ...rulesSlack,
  ...rulesUniversal,
};
const allRules = { ...rules, ...rulesSlack, ...rulesUniversal };

const parser = markdown.parserFor(allRules);
const htmlOutput = markdown.htmlFor(markdown.ruleOutput(allRules, "html"));
const parserSlack = markdown.parserFor(slackOnlyRules);
const htmlOutputSlack = markdown.htmlFor(
  markdown.ruleOutput(slackOnlyRules, "html"),
);

export type toHTMLOptions = {
  escapeHTML?: boolean;
  slackOnly?: boolean;
  slackCallbacks?: any;
  cssModuleNames?: any;
  noExtraSpanTags?: boolean;
  noExtraEmojiSpanTags?: boolean;
  hrefTarget?: string;
  hrefFollowURLsFromHosts?: string[];
  noInlineCode?: boolean;
};

export type stateType = {
  inline: boolean;
  inQuote: boolean;
  escapeHTML: boolean;
  cssModuleNames: any;
  slackCallbacks: any;
  noExtraSpanTags: boolean;
  noExtraEmojiSpanTags: boolean;
  hrefTarget?: string;
  hrefFollowURLsFromHosts?: string[];
  noInlineCode?: boolean;
};

export function toHTML(source: string, opts?: toHTMLOptions): string {
  const options: toHTMLOptions = {
    escapeHTML: false,
    slackOnly: false,
    slackCallbacks: {},
    cssModuleNames: {},
    noExtraSpanTags: false,
    noExtraEmojiSpanTags: false,
    hrefTarget: "",
    hrefFollowURLsFromHosts: [],
    noInlineCode: false,
    ...opts,
  };

  const _parser = options.slackOnly ? parserSlack : parser;
  const _htmlOutput = options.slackOnly ? htmlOutputSlack : htmlOutput;

  const state = {
    inline: true,
    inQuote: false,
    escapeHTML: options.escapeHTML,
    cssModuleNames: options.cssModuleNames,
    slackCallbacks: {
      ...slackCallbackDefaults,
      ...options.slackCallbacks,
    },
    noExtraSpanTags: options.noExtraSpanTags,
    noExtraEmojiSpanTags: options.noExtraEmojiSpanTags,
    hrefTarget: options.hrefTarget,
    hrefFollowURLsFromHosts: options.hrefFollowURLsFromHosts,
    noInlineCode: options.noInlineCode,
  };

  return _htmlOutput(_parser(source, state), state);
}
