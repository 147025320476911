import React, { useEffect, useState } from "react";
import styles from "./FeedItemFooter.module.scss";
import ThreadReactions from "@/app/components/Reactions/ThreadReactions";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { Badge, Flex, Text, Separator } from "@radix-ui/themes";
import { ConnectIcon } from "@/app/components/icons/ConnectIcon";
import { CustomCheckCircledIcon } from "@/app/components/icons/CustomCheckCircledIcon";
import { motion } from "framer-motion";
import { TypingAnimation } from "@/app/components/TypingAnimation";
import TypingIndicator from "@/app/components/TypingIndicator";

interface FeedItemFooterProps {
  reactions: any;
  messageCount?: any;
  num_unread?: any;
  createdDate: any;
  lastUpdated: string;
  isResolved?: any;
  typingUsers?: any;
}

const FeedItemFooter = ({
  reactions,
  messageCount,
  num_unread,
  createdDate,
  lastUpdated,
  isResolved,
  typingUsers,
}: FeedItemFooterProps) => {
  const shouldShowSeparator = messageCount || num_unread > 0;

  return (
    <Flex align="center" className={styles.threadFooter} wrap="wrap">
      {reactions && reactions.length > 0 && (
        <>
          <ThreadReactions reactions={reactions} />
          <Separator size="1" orientation="vertical" mx="2" />
        </>
      )}
      {messageCount > 0 && (
        <>
          <ChatBubbleIcon style={{ marginRight: "var(--space-1)" }} />
          <Text size="1">{messageCount}</Text>
        </>
      )}
      {num_unread > 0 && <Badge ml="2">{num_unread} new</Badge>}
      {shouldShowSeparator && (
        <Separator size="1" orientation="vertical" mx="2" />
      )}
      <Text size="1" mr="1">
        {createdDate}
      </Text>
      <ConnectIcon
        style={{
          marginRight: "var(--space-1)",
        }}
      />
      <Text size="1">{lastUpdated}</Text>
      {isResolved && (
        <>
          <Separator size="1" orientation="vertical" mx="2" />
          <CustomCheckCircledIcon
            style={{
              color: "var(--green-10)",
            }}
          />
        </>
      )}
      {typingUsers && typingUsers.length > 0 && (
        <motion.div
          layout="position"
          style={{
            display: "flex",
            alignItems: "center",
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <Separator size="1" orientation="vertical" mx="2" />
          <TypingAnimation />
          <TypingIndicator
            style={{
              color: "var(--slate-11)",
            }}
            names={typingUsers}
          />
        </motion.div>
      )}
    </Flex>
  );
};

export default FeedItemFooter;
