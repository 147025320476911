// Struct Server Endpoint
export const STRUCT_API_URL = process.env.NEXT_PUBLIC_STRUCT_SERVER_URL || "";
export const STRUCT_API_VERSION = process.env.NEXT_PUBLIC_STRUCT_API_VERSION;
export const STRUCT_ACTIVE_THEME = "STRUCT_ACTIVE_THEME";
export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;

// posthog
export const POST_HOG_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;
export const POST_HOG_HOST = process.env.NEXT_PUBLIC_POSTHOG_HOST;

// Struct app endpoint
export const STRUCT_APP_URL = process.env.NEXT_PUBLIC_STRUCT_APP_URL;

// auth
export const ID_SERVER_URL = process.env.NEXT_PUBLIC_ID_SERVER_URL;
