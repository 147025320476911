import { Flex } from "@radix-ui/themes";
import styles from "./FileUploadAnimation.module.scss";

interface FileUploadAnimationProps {
  style?: React.CSSProperties;
}

export const FileUploadAnimation = ({ style }: FileUploadAnimationProps) => (
  <Flex className={styles.fileUploadAnimation} style={style}>
    <span></span>
    <span></span>
    <span></span>
  </Flex>
);
