import { UserEntity, ChannelEntity } from "./../types/Thread.type";
import { DateTime } from "luxon";
import { StructChannel } from "@/app/types/Channel.type";
import {
  FeedThreadResponse,
  ReactionResponse,
  StructChat,
  StructChatGroupedByDate,
  StructChatResponse,
  StructThread,
  StructThreadResponse,
  StructUser,
} from "@/app/types/Thread.type";
import { replaceMentionedUserAndChannelIdsWithNames } from "./stringUtils";
import { toHTML } from "@/app/modules/slack-markdown";
import { formatTimeAgoForDateGroup } from "./timeUtils";
import { StructOrganisation } from "@/app/types/Organisation.type";
import { getAnonymousUser } from "@/app/utils/users";
import { EntityIDMap } from "../constants";

/** Get the user from the indexed users */
const getUser = (
  user_id: string,
  usersById: Record<StructUser["id"], StructUser>,
  organisation?: StructOrganisation,
): StructUser => {
  const user = usersById?.[user_id];

  if (user) {
    return user;
  }

  return getAnonymousUser(organisation);
};

/** Joins indexed users and channels into a thread */
export const mergeThreadData = (
  thread: StructThreadResponse | FeedThreadResponse,
  organisation: StructOrganisation,
): StructThread => {
  const reactions: ReactionResponse[] =
    thread.reactions?.map(
      ({ count, name, user_ids, block }: ReactionResponse) => {
        const reaction: ReactionResponse = {
          name: name,
          count: count,
          user_ids: user_ids || [],
          block,
        };
        return reaction;
      },
    ) || [];

  let chatLink = `https://${organisation?.xid}.slack.com`;
  try {
    const [_, channelid, messageId] = thread.xid.split("-");
    if (messageId) {
      chatLink += `/archives/${channelid}/p${messageId.split(".").join("")}`;
    }
  } catch (error) {
    console.error(error);
  }

  const entityMap = thread.entity_map ?? {};

  return {
    ...thread,
    subject: replaceMentionedUserAndChannelIdsWithNames(
      thread.subject,
      entityMap as UserEntity,
      entityMap as ChannelEntity,
    ).replace(/<[^>]+>/g, ""),
    summaryHTML: replaceMentionedUserAndChannelIdsWithNames(
      toHTML(thread.summary),
      entityMap as UserEntity,
      entityMap as ChannelEntity,
    ),
    summaryText: replaceMentionedUserAndChannelIdsWithNames(
      thread.summary,
      entityMap as UserEntity,
      entityMap as ChannelEntity,
    ).replace(/<[^>]+>/g, ""),
    chatLink,
    reactions,
    sortedReactions: reactions.sort((a, b) => b.count - a.count).slice(0, 3),
    totalReactions: reactions
      .map((reaction) => reaction.count)
      .reduce((partialSum, count) => partialSum + count, 0),
    participants: thread.user_ids
      ?.filter((user_id) => user_id !== thread.author_id)
      .map((userId) => getUser(userId, entityMap as UserEntity, organisation)),
    user: getUser(thread.author_id, entityMap as UserEntity, organisation),
    lastUpdated: thread.updated_at || thread.created_at,
    createdDate: DateTime.fromSeconds(thread.created_at).toFormat(
      "LLL dd, yyyy",
    ),
    formattedDate: DateTime.fromSeconds(
      thread.updated_at || thread.created_at,
    ).toFormat("LLL dd, yyyy"),
    channels:
      thread.xsr_ids
        ?.filter((id) => id.startsWith(EntityIDMap.Channel))
        ?.reduce<StructChannel[]>(
          (result, id) =>
            (entityMap as ChannelEntity)[id]?.name
              ? [...result, (entityMap as ChannelEntity)[id]]
              : result,
          [],
        ) || [],
    DMs: (
      thread.xsr_ids
        ?.filter((id) => id.startsWith(EntityIDMap.User))
        ?.filter((id) => thread.author_id !== id) || []
    )
      .map((id) => (entityMap as UserEntity)[id])
      .filter(Boolean),
  };
};

/** Converts a given time to be used to group dates within a day
 * returns in the format of "Apr 12, 2023 (12 days ago)"
 */
export const convertDateToGroupHeader = (chatDate: DateTime): string =>
  `${chatDate.toFormat("LLL dd, yyyy")} (${formatTimeAgoForDateGroup(
    chatDate,
  )})`;

/**
 * Function to join all the different data points into thread Data
 * @param thread API response Data for the thread
 * @returns
 */
export const joinThreadData = ({
  thread,
  organisation,
}: {
  thread: StructThreadResponse;
  organisation: StructOrganisation;
}): StructThread => {
  return mergeThreadData(thread, organisation);
};

/**
 * Function to join all the different data points into a list of thread Data
 * @param threads API response Data for the threads list
 * @param users API response Data for the users
 * @param channels API response Data for the channels
 * @returns
 */
export const joinManyThreadsData = ({
  threads,
  organisation,
}: {
  threads: StructThreadResponse[] | FeedThreadResponse[];
  organisation: StructOrganisation;
}): StructThread[] => {
  try {
    return (
      threads?.map((thread) => mergeThreadData(thread, organisation)) || []
    );
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * returns true if the channel list has channels accessible for the public
 * @param channels
 * @returns
 */
export const orgHasPublicChannels = (channels: StructChannel[]): boolean =>
  channels?.some((channel) => channel.bits?.chan_anyone) || false;

/**
 * returns true if the channel list has channels only accessible for the team
 * @param channels
 * @returns
 */
export const orgHasPrivateChannels = (channels: StructChannel[]): boolean =>
  channels?.some((channel) => channel.bits?.chan_member) || false;

// TODO: Change this to use functional programming
export const getGroupedChatByDate = (
  chats: StructChat[],
): StructChatGroupedByDate => {
  return chats
    .sort((a, b) => a.created_at - b.created_at)
    .reduce<StructChatGroupedByDate>((result, chatData) => {
      const dateGroup = convertDateToGroupHeader(
        DateTime.fromSeconds(chatData.created_at),
      );

      result[dateGroup] = result[dateGroup]
        ? [...result[dateGroup], chatData]
        : [chatData];

      return result;
    }, {});
};
