import { structAPIFetchClient } from "./client";
import { STRUCT_API_VERSION } from "@/app/utils/constants";
import SearchResult from "@/app/types/Search.type";
import { SortingOption } from "@/app/types/Sorting.type";

const SEARCH_ENDPOINT = `/${STRUCT_API_VERSION}/search`;

export interface SearchResponse {
  facet_counts: [string];
  found: number;
  hits: [SearchResult];
  out_of: number;
  page: number;
  request_params: {
    collection_name: string;
    per_page: number;
    q: string;
  };
  search_cutoff: boolean;
  search_time_ms: number;
}

export type FilterOptions = {
  users: string[];
  channels: string[];
  tags: string[];
};

const getFormattedFilterOptions = (
  filterOptions?: FilterOptions,
): string | undefined => {
  if (
    !filterOptions ||
    (filterOptions.channels?.length === 0 &&
      filterOptions.users?.length === 0 &&
      filterOptions.tags?.length === 0)
  ) {
    return;
  }
  const arrayOfOptions = Object.entries(filterOptions).reduce(
    (result, [key, value]) => {
      if (key === "users" && value.length > 0) {
        return [...result, `user_ids := [${value.join(",")}]`];
      }
      if (key === "channels" && value.length > 0) {
        return [...result, `xsr_ids := [${value.join(",")}]`];
      }
      if (key === "tags" && value.length > 0) {
        return [...result, `tags := [${value.join(",")}]`];
      }

      return result;
    },
    [] as string[],
  );

  return arrayOfOptions.join(" && ");
};

export const getSearchResultsAPI = async ({
  q,
  page,
  sortBy,
  filterBy,
  from,
  to,
}: {
  q?: string;
  page?: number;
  sortBy?: SortingOption;
  filterBy?: FilterOptions;
  from?: number;
  to?: number;
}) => {
  const queryParams = new URLSearchParams();
  if (from !== undefined)
    queryParams.set("from", Math.round(from / 1000).toString());
  if (to !== undefined) queryParams.set("to", Math.round(to / 1000).toString());
  const response = await structAPIFetchClient(
    `${SEARCH_ENDPOINT}?${queryParams.toString()}`,
    {
      method: "POST",
      body: JSON.stringify({
        q,
        page,
        ...(sortBy ? { sort_by: sortBy } : {}),
        filter_by: getFormattedFilterOptions(filterBy),
      }),
    },
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return {
    formattedText: data.q_plain,
    hits: data.data,
  };
};
