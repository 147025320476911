import { IconType } from "@/app/components/icons/icon.type";

export const CustomCheckCircledIcon = ({ style, className }: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    style={style}
    className={className}
  >
    <rect width="16" height="16" fill="transparent" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.9999 0.935547C4.09837 0.935547 0.935547 4.09837 0.935547 7.9999C0.935547 11.9014 4.09837 15.0642 7.9999 15.0642C11.9014 15.0642 15.0642 11.9014 15.0642 7.9999C15.0642 4.09837 11.9014 0.935547 7.9999 0.935547ZM10.8362 5.90696C11.0056 5.66607 10.9478 5.33338 10.707 5.16386C10.4661 4.99435 10.1334 5.05222 9.96385 5.2931L6.95441 9.56968L5.71392 8.30623C5.50756 8.09606 5.16988 8.09295 4.9597 8.29932C4.74952 8.50568 4.74642 8.84335 4.95279 9.05353L6.64168 10.7737C6.75257 10.8866 6.90791 10.9445 7.06567 10.9316C7.22343 10.9188 7.36731 10.8364 7.4584 10.7069L10.8362 5.90696Z"
      fill="currentColor"
    />
  </svg>
);
