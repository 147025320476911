import {
  FeedThreadResponse,
  Read,
  StructChatResponse,
  StructFeedThreadResponse,
  StructThread,
  StructThreadResponse,
} from "@/app/types/Thread.type";
import { structAPIFetchClient } from "./client";
import { STRUCT_API_VERSION } from "@/app/constants";
import { SortingOption } from "@/app/types/Sorting.type";

const THREADS_ENDPOINT = `/${STRUCT_API_VERSION}/threads`;

export interface StructAPIThreadsResponse {
  threads: StructThreadResponse[];
  next_cursor: string;
}

export const getAllThreadsAPI = async ({ cursor }: { cursor?: string }) => {
  const response = await structAPIFetchClient(THREADS_ENDPOINT, {
    method: "GET",
    body: JSON.stringify({
      ...(cursor ? { cursor } : {}),
      limit: 30,
      asc: false,
    }),
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return data as StructThreadResponse[];
};

export interface StructThreadAPIResponse {
  read: Read;
  chats: StructChatResponse[];
  thread: StructThreadResponse;
  similar_threads: StructThreadResponse[];
  join_chat: String;
  read_until: StructChatResponse["id"];
}

export const getThreadByIdAPI = async (threadId: string) => {
  const response = await structAPIFetchClient(
    `${THREADS_ENDPOINT}/${threadId}`,
    {
      method: "GET",
    },
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  if (data.thread && data.read && !data.thread.read) {
    data.thread.read = data.read;
  }
  return data as StructThreadAPIResponse;
};

export const putHideThreadsAPI = (threadIds: string[]) => {
  return structAPIFetchClient(`${THREADS_ENDPOINT}`, {
    method: "PUT",
    body: JSON.stringify({
      bits: { hidden: true },
      thread_ids: threadIds,
    }),
  });
};

export const putUnhideThreadsAPI = (threadIds: string[]) => {
  return structAPIFetchClient(`${THREADS_ENDPOINT}`, {
    method: "PUT",
    body: JSON.stringify({
      bits: { hidden: true, flip: true },
      thread_ids: threadIds,
    }),
  });
};

export const putDeleteThreadsAPI = (threadIds: string[]) => {
  return structAPIFetchClient(`${THREADS_ENDPOINT}`, {
    method: "PUT",
    body: JSON.stringify({
      // always send true even if we want to delete/undelete
      bits: { deleted: true },
      thread_ids: threadIds,
      op: "set",
    }),
  });
};

export interface StructFeedResponse {
  id: string;
  user_id: string;
  org_id: string;
  name: string;
  channel_ids: null | string[];
  user_ids: null | string[];
  bits: {};
}

export interface SearchFeedParams {
  q?: string;
  page?: number;
  filterBy?: FilterOptions;
  sortBy?: SortingOption;
  from?: number;
  to?: number;
}

export type FilterOptions = {
  users: string[];
  channels: string[];
  tags: string[];
};

const getFormattedFilterOptions = (
  filterOptions?: FilterOptions,
): string | undefined => {
  if (
    !filterOptions ||
    (filterOptions.channels?.length === 0 &&
      filterOptions.users?.length === 0 &&
      filterOptions.tags?.length === 0)
  ) {
    return;
  }
  const arrayOfOptions = Object.entries(filterOptions).reduce(
    (result, [key, value]) => {
      if (key === "users" && value.length > 0) {
        return [...result, `user_ids := [${value.join(",")}]`];
      }
      if (key === "channels" && value.length > 0) {
        return [...result, `xsr_ids := [${value.join(",")}]`];
      }
      if (key === "tags" && value.length > 0) {
        return [...result, `tags := [${value.join(",")}]`];
      }
      return result;
    },
    [] as string[],
  );

  return arrayOfOptions.join(" && ");
};

export const searchFeedAPI = async ({
  q,
  page,
  filterBy,
  sortBy,
  from,
  to,
}: SearchFeedParams): Promise<StructFeedThreadResponse> => {
  const queryParams = new URLSearchParams();
  if (page !== undefined) queryParams.set("page", page.toString());
  const body = {
    q,
    ...(sortBy ? { sort_by: sortBy } : {}),
    filter_by: getFormattedFilterOptions(filterBy),
  };

  // @ts-expect-error
  if (from !== undefined) body["from"] = Math.round(from / 1000).toString();
  // @ts-expect-error
  if (to !== undefined) body["to"] = Math.round(to / 1000).toString();

  const endpoint = `/v1/feed/search`;

  const response = await structAPIFetchClient(
    `${endpoint}?${queryParams.toString()}`,
    {
      method: "POST",
      // @ts-ignore
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data as StructFeedThreadResponse;
};

export interface CreateThreadParams {
  message: string;
  channelIds: StructThread["xsr_ids"];
  attachments?: Array<string>;
}

type CreateThreadResponse = {
  thread_id: StructThreadResponse["id"];
  chat_id: StructChatResponse["id"];
};

export const createThreadAPI = async ({
  message,
  channelIds,
  attachments,
}: CreateThreadParams): Promise<CreateThreadResponse> => {
  const response = await structAPIFetchClient(THREADS_ENDPOINT, {
    method: "POST",

    body: JSON.stringify({
      text: message,
      xsr_ids: channelIds,
      attachments,
    }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data as CreateThreadResponse;
};
